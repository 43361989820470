import React, { useState } from 'react'
import { Button, Row, Col, Input } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { DEFAULT_VALUES } from '../../../../utils/constants'

const Work = ({ resume, setResume }) => {
  const [work, setWork] = useState(resume.data.work)

  const handleChange = (target, index) => {
    const { name, value } = target
    const updatedWork = work.map((exp, i) =>
      index === i
        ? {
            ...exp,
            [name]: value,
          }
        : exp
    )
    setWork(updatedWork)
    setResume({
      ...resume,
      data: {
        ...resume.data,
        work: updatedWork,
      },
    })
  }

  const addWork = () => {
    const updatedWork = [...work, ...DEFAULT_VALUES.work]
    setWork(updatedWork)
    setResume({
      ...resume,
      data: {
        ...resume.data,
        work: updatedWork,
      },
    })
  }

  const deleteWork = (i) => {
    const updatedWork = work.filter((_, index) => index !== i)
    setWork(updatedWork)
    setResume({
      ...resume,
      data: {
        ...resume.data,
        work: updatedWork,
      },
    })
  }

  return (
    <>
      {(work?.length < 3 || work?.length === 0) && (
        <Button
          className="mt-2 mb-4"
          type="default"
          icon={<PlusOutlined />}
          onClick={addWork}
        >
          Add Work Experience
        </Button>
      )}
      {work?.length
        ? work.map(
            (
              { position, organization, startDate, endDate, description },
              index
            ) => (
              <div className="mb-3">
                <Row gutter={[12, 12]}>
                  <Col md={24}>
                    <Input
                      value={position}
                      placeholder="Position"
                      name="position"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>

                  <Col md={24}>
                    <Input
                      value={organization}
                      placeholder="Organization"
                      name="organization"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>

                  <Col md={12}>
                    <Input
                      value={startDate}
                      placeholder="Start Date (Month/Year)"
                      name="startDate"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>

                  <Col md={12}>
                    <Input
                      value={endDate}
                      placeholder="End Date (Month/Year)"
                      name="endDate"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>

                  <Col md={24}>
                    <Input.TextArea
                      value={description}
                      placeholder="Description"
                      name="description"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Button
                    type="primary"
                    onClick={() => deleteWork(index)}
                    icon={<DeleteOutlined />}
                    danger
                    className="mt-3 mb-4"
                  >
                    Delete
                  </Button>
                </Row>
              </div>
            )
          )
        : null}
    </>
  )
}

export default Work
