import React, { useState } from 'react'
import { Button, Row, Col, Input, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'

const Skills = ({ resume, setResume }) => {
  const [skills, setSkills] = useState(resume.data.skills)
  const [label, setLabel] = useState('')

  const addSkill = () => {
    const updatedSkills = [
      ...skills,
      {
        id: uuidv4(),
        label,
      },
    ]
    setSkills(updatedSkills)
    setLabel('')
    setResume({
      ...resume,
      data: {
        ...resume.data,
        skills: updatedSkills,
      },
    })
  }

  const deleteSkill = (id) => {
    const updatedSkills = skills.filter((elem) => elem.id !== id)
    setSkills(updatedSkills)
    setResume({
      ...resume,
      data: {
        ...resume.data,
        skills: updatedSkills,
      },
    })
  }

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col md={20}>
          <Input
            placeholder="Add skill"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </Col>
        <Col>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => addSkill()}
          />
        </Col>
      </Row>
      <Row>
        <Col md={20} className="mt-2">
          {skills?.length
            ? skills.map(({ id, label }) => (
                <Tag
                  color="#87d068"
                  key={id}
                  className="fs-5 p-2 mb-2"
                  closable
                  onClose={() => deleteSkill(id)}
                >
                  {label}
                </Tag>
              ))
            : null}
        </Col>
      </Row>
    </>
  )
}

export default Skills
