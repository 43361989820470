import { v4 as uuidv4 } from 'uuid'

export const getResumes = () => {
  const resumes = localStorage.getItem('resumes')
  return resumes ? JSON.parse(resumes) : []
}

export const getResumeById = (id) => {
  const resumes = getResumes()
  return resumes.find((resume) => resume.id === id)
}

export const addResume = ({ name, data }) => {
  const newResume = {
    id: uuidv4(),
    name,
    data,
    updatedAt: new Date().toDateString(),
  }

  const resumes = getResumes()

  const updatedResumes = [...resumes, newResume]

  localStorage.setItem('resumes', JSON.stringify(updatedResumes))
}

export const updateResumeById = ({ id, data }) => {
  const resumes = getResumes()

  const updatedResumes = resumes.map((resume) => {
    if (resume.id === id) {
      return {
        ...resume,
        ...data,
        updatedAt: new Date().toDateString(),
      }
    }
    return resume
  })

  localStorage.setItem('resumes', JSON.stringify(updatedResumes))
}

export const deleteResume = (id) => {
  const resumes = getResumes()
  const updatedResumes = resumes.filter((resume) => resume.id !== id)
  localStorage.setItem('resumes', JSON.stringify(updatedResumes))
}
