import React from 'react'
import { Card, Row, Typography } from 'antd'
import { socialLinks } from '../../utils/constants'
import { LinkedinFilled, GithubFilled, TwitterCircleFilled } from '@ant-design/icons'

const { Title } = Typography


const Header = () => {
  const clickHandler = (name) => {
    window.open(socialLinks[name])
  }

  return <Row className='shadow-sm'>
    <Card className='w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        <Title level={3} className="mb-0 pe-2">ProResume</Title>
        <div className='fs-3'>
          <GithubFilled role='button' onClick={() => clickHandler('github')} />
          <TwitterCircleFilled className='ms-2' role='button' onClick={() => clickHandler('twitter')} />
          <LinkedinFilled className='ms-2' role='button' onClick={() => clickHandler('linkedin')} />
        </div>
        <Title level={3} className="my-0">© {new Date().getFullYear()} | Deepansh Bhargava</Title>
      </div>
    </Card>
  </Row>
}

export default Header
