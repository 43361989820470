import React from 'react'
import { Row, Col, Form, Input } from 'antd'

const Contact = ({ resume, setResume }) => {
  return (
    <Form
      initialValues={resume.data.personalInformation}
      onValuesChange={(_, values) =>
        setResume({
          ...resume,
          data: {
            ...resume.data,
            personalInformation: {
              ...resume.personalInformation,
              ...values,
            },
          },
        })
      }
    >
      <Row gutter={12}>
        <Col md={12}>
          <Form.Item name="name">
            <Input placeholder="Full Name" />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item name="role">
            <Input placeholder="Role" />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item name="email">
            <Input placeholder="Email" />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item name="phone">
            <Input placeholder="Phone" />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item name="location">
            <Input placeholder="Location" />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item name="linkedIn">
            <Input placeholder="Linkedin" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default Contact
