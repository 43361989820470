import React, { useState } from 'react'
import { Button, Row, Col, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { DEFAULT_VALUES } from '../../../../utils/constants'

const Education = ({ resume, setResume }) => {
  const [education, setEducation] = useState(resume.data.education)

  const handleChange = (target, index) => {
    const { name, value } = target
    const updatedEducation = education.map((details, i) =>
      index === i
        ? {
            ...details,
            [name]: value,
          }
        : details
    )
    setEducation(updatedEducation)
    setResume({
      ...resume,
      data: {
        ...resume.data,
        education: updatedEducation,
      },
    })
  }

  return (
    <>
      {education?.length
        ? education.map(
            ({ course, institution, startDate, endDate }, index) => (
              <div className="mb-3">
                <Row gutter={[12, 12]}>
                  <Col md={24}>
                    <Input
                      value={course}
                      placeholder="Course"
                      name="course"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>

                  <Col md={24}>
                    <Input
                      value={institution}
                      placeholder="Institution"
                      name="institution"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>

                  <Col md={12}>
                    <Input
                      value={startDate}
                      placeholder="Start Date (Month/Year)"
                      name="startDate"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>

                  <Col md={12}>
                    <Input
                      value={endDate}
                      placeholder="End Date (Month/Year)"
                      name="endDate"
                      onChange={(e) => handleChange(e.target, index)}
                    />
                  </Col>
                </Row>
              </div>
            )
          )
        : null}
      {education?.length < 2 && (
        <Button
          className="mt-2"
          type="dashed"
          icon={<PlusOutlined />}
          onClick={() => {
            const updatedEducation = [...education, ...DEFAULT_VALUES.education]
            setEducation(updatedEducation)
            setResume({
              ...resume,
              data: {
                ...resume.data,
                education: updatedEducation,
              },
            })
          }}
        >
          Add Education
        </Button>
      )}
    </>
  )
}

export default Education
