import { useState } from 'react'

import { Modal, Card, Layout, Button, Row, Col, Typography, Input } from 'antd'
import { addResume, deleteResume, getResumes } from './utils/helpers'

import { FilePdfTwoTone, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { DEFAULT_VALUES, features } from './utils/constants'
import { useNavigate } from 'react-router-dom'
import { Footer } from './components/Footer'

import styles from './App.module.css'

const { Meta } = Card

const { Title } = Typography

function App() {
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [resumes, setResumes] = useState(getResumes())
  const navigate = useNavigate()

  const toggleModal = () => setShow((show) => !show)

  const handleAddResume = () => {
    addResume({ name, data: DEFAULT_VALUES })
    toggleModal()
    setName('')
    setResumes(getResumes())
  }

  const handleDeleteResume = (id) => {
    deleteResume(id)
    setResumes(getResumes())
  }

  return (
    <Layout className={`w-100 ${styles.layout}`}>
      <Title className="text-center mb-0" level={1}>
        ProResume
      </Title>
      <Title className="text-center mt-0" level={4}>
        You can view your previous resumes or create a new one.
      </Title>
      <Row justify={'center'} className="mt-3">
        <a href="https://www.producthunt.com/posts/proresume?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-proresume" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=439079&theme=light" alt="ProResume - Simple&#0032;&#0038;&#0032;User&#0032;Friendly&#0032;Resume&#0032;Maker | Product Hunt" style={{
          width: '250px', height: '54px'
        }} width="250" height="54" /></a>
      </Row>

      <div className='bg-white my-5 p-3 shadow-sm'>
        <Row justify={'space-between'} align={'middle'}>
          <Title className="mt-0" level={3}>
            My Resumes
          </Title>
          <Button type="primary" className="mb-4" onClick={toggleModal}>
            Add Resume
          </Button>
        </Row>
        <Row className="mb-4" gutter={16}>
          {resumes?.length ? (
            resumes.map(({ name, id, updatedAt }) => (
              <Col md={4} xs={24}>
                <Card
                  key={id}
                  className="mb-4 shadow-sm"
                  actions={[
                    <EditOutlined
                      key="edit"
                      onClick={() => navigate(`/resumes/${id}`)}
                    />,
                    <DeleteOutlined
                      key="delete"
                      onClick={() => handleDeleteResume(id)}
                    />,
                  ]}
                >
                  <Meta title={name} description={`Last Updated: ${updatedAt}`} />
                </Card>
              </Col>
            ))
          ) : (
            <Col md={24} xs={24}>
              <div className="text-center mt-5">
                <FilePdfTwoTone className="fs-1 d-block" />
                <p className="mt-3">
                  Nothing to show here. Please create a resume.
                </p>
              </div>
            </Col>
          )}
        </Row>
      </div>
      <Row className="mb-5" gutter={16}>
        <Col md={24} xs={24}>
          <Card className='shadow-sm mx-auto'
            style={{
              fontSize: '18px'
            }}
          >
            <div className='p-3'>
              <p>
                Tired of online resume makers and paying for irrelevant features? Say goodbye to those frustrations with ProResume.
              </p>
              <p>
                ProResume is both free and prioritizes privacy. Here are the features it offers:
              </p>
            </div>
            <ul className='pl-2'>
              {features.map(({ title, description }) => (
                <li className='mb-4' key={title}><strong>{title}:</strong> {description}</li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row>
      <Footer />
      <Modal
        title="Add Resume"
        open={show}
        onOk={handleAddResume}
        onCancel={toggleModal}
      >
        <Input
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Modal>
    </Layout>
  )
}

export default App
