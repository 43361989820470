import { Spin, Typography, Layout, Row, Col } from 'antd'
import React, { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getResumeById, updateResumeById } from '../../utils/helpers'
import { Builder } from '../Builder'
import { Preview } from '../Preview'
import { Download } from '../Download'
import { themeColors } from '../../utils/constants'
import styles from './Resume.module.css'

const { Text } = Typography

const Resume = () => {
  const { id } = useParams()
  const [resume, setResume] = useState('')
  const [theme, setTheme] = useState(themeColors[0])

  useEffect(() => {
    const resume = getResumeById(id)
    setResume(resume)
  }, [id])

  useEffect(() => {
    updateResumeById({ id, data: resume })
  }, [id, resume])

  const ref = useRef()

  if (!resume) {
    return <Spin size="large" />
  }

  return (
    <Layout className={`w-100 ${styles.layout}`}>
      <Row>
        <Text className="fw-bold fs-4">Resume's Name: {resume.name}</Text>
      </Row>
      <div>
        <p className="fw-bold fs-4">Theme:</p>
        <div className="d-flex gap-3 mb-4">
          {themeColors.map((color) => (
            <div
              onClick={() => setTheme(color)}
              className={`mr-4 ${styles.themeOption}`}
              style={{
                border: theme === color ? '4px solid red' : 'none',
                background: color,
              }}
            />
          ))}
        </div>
      </div>
      <Download resumeRef={ref} title={resume.name} />
      <Row gutter={32}>
        <Col md={10} xs={24}>
          <Builder resume={resume} setResume={setResume} />
        </Col>
        <Col md={14} xs={24} className="shadow p-0 w-100">
          <Preview
            resume={resume}
            setResume={setResume}
            resumeRef={ref}
            theme={theme}
          />
        </Col>
      </Row>
    </Layout>
  )
}

export default Resume
