export const DEFAULT_VALUES = {
  personalInformation: {
    name: 'John Doe',
    role: 'Software Engineer',
    email: 'john@example.com',
    phone: '91234567890',
    location: 'India',
    linkedIn: 'https://linkedIn.com/',
  },
  work: [
    {
      position: 'Great Developer',
      organization: 'Organization',
      startDate: 'Aug/2021',
      endDate: 'Aug/2023',
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ],
  skills: [{ id: 1, label: 'Javascript' }],
  education: [
    {
      institution: 'Institution',
      course: 'Course',
      startDate: 'Apr/2005',
      endDate: 'Apr/2014',
    },
  ],
}

export const themeColors = ['#69599c', '#449399', '#2c363f', '#ff7d8a']

export const features = [
  {
    title: 'Real-time Builder',
    description: 'Instantly create and manage multiple resumes, with the ability to view your changes in real time. Effortlessly tailor your resumes to each opportunity, ensuring they reflect your best self with every edit.'
  },
  {
    title: 'Offline Data Storage',
    description: 'Safely store and update your resumes offline, giving you peace of mind and control over your personal information, wherever you are.'
  },
  {
    title: 'Multiple Themes',
    description: 'Choose from a variety of visually appealing themes to showcase your unique style, empowering you to make a lasting impression on potential employers while addressing your desire for customization and personalization.'
  },
  {
    title: 'Section Customization',
    description: 'Tailor each section of your resumes to highlight your skills and experiences, ensuring you stand out in the competitive job market while addressing your need for personalized representation.'
  },
  {
    title: 'One-Click Resume Export',
    description: 'Easily download your finished resumes, ready to impress employers and accelerate your job search, while ensuring your documents are readily accessible for sharing and printing.'
  }
]

export const socialLinks = {
  github: 'https://github.com/deepansh946'
  , linkedin: 'https://linkedin.com/in/deepansh946'
  , twitter: 'https://twitter.com/deepansh946'
}
