import { Tag, Typography, Layout, Row, Col } from 'antd'
import React from 'react'
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  LinkedinOutlined,
} from '@ant-design/icons'

import styles from './Preview.module.css'

const { Title, Text } = Typography

const Preview = ({ resume, resumeRef, theme }) => {
  const { personalInformation, education, work, skills } = resume.data

  const { name, role, email, phone, location, linkedIn } = personalInformation
  return (
    <Layout
      className={`w-100 overflow-hidden ${styles.layout}`}
      ref={resumeRef}
    >
      <Row
        gutter={16}
        className="text-white p-4"
        style={{
          background: theme,
        }}
        justify={'space-between'}
      >
        <Col md={14} xs={14}>
          <Title className="text-white mb-0" level={2}>
            {name}
          </Title>
          <Title className="text-white mt-2" level={4}>
            {role}
          </Title>
        </Col>
        <Col md={10} xs={10} className="text-align-right">
          <Row justify={'end'} align={'middle'} className="mb-2" wrap={false}>
            <Text className="text-white pe-2">{email}</Text>
            <MailOutlined className="fs-5" />
          </Row>
          <Row justify={'end'} align={'middle'} className="mb-2" wrap={false}>
            <Text className="text-white pe-2">{phone}</Text>
            <PhoneOutlined className="fs-5" />
          </Row>
          <Row justify={'end'} align={'middle'} className="mb-2" wrap={false}>
            <Text className="text-white pe-2">{location}</Text>
            <EnvironmentOutlined className="fs-5" />
          </Row>
          <Row justify={'end'} align={'middle'} className="mb-2" wrap={false}>
            <Text className="text-white pe-2">{linkedIn}</Text>
            <LinkedinOutlined className="fs-5" />
          </Row>
        </Col>
      </Row>
      <Row className="text-black p-4" justify={'space-between'} gutter={16}>
        <Col md={12} xs={12}>
          <div className="mb-4">
            <Title className="text-uppercase mb-2" level={3}>
              Education
            </Title>
            {education.map(({ course, institution, startDate, endDate }) => (
              <>
                {course && <p className="fw-bold mb-0 fs-5">{course}</p>}
                {institution && <p className="fs-6 mb-0">{institution}</p>}
                {startDate && endDate && (
                  <p style={{ color: '#449399' }}>
                    {startDate} - {endDate}
                  </p>
                )}
              </>
            ))}
          </div>

          <div>
            <Title className="text-uppercase mb-2" level={3}>
              Work Experience
            </Title>
            {work.map(
              ({ position, organization, startDate, endDate, description }) => (
                <div className="mb-3">
                  {position && <p className="fw-bold mb-0 fs-6">{position}</p>}
                  {organization && <p className="fs-6 mb-0">{organization}</p>}
                  {startDate && endDate && (
                    <p style={{ color: '#449399' }} className="mb-0">
                      {startDate} - {endDate}
                    </p>
                  )}
                  {description && <p className="mb-0">{description}</p>}
                </div>
              )
            )}
          </div>
        </Col>
        <Col md={12} xs={12}>
          <div>
            <Title className="text-uppercase mb-2" level={3}>
              Skills
            </Title>
            {skills.map(({ id, label }) => (
              <Tag key={id} color={theme} className="mb-2 p-2 fs-6">
                {label}
              </Tag>
            ))}
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Preview
