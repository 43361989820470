import React from 'react'
import { Row, Tabs } from 'antd'

import { Contact } from './components/Contact'
import { Education } from './components/Education'
import { Work } from './components/Work'
import { Skills } from './components/Skills'

const Builder = ({ resume, setResume }) => {
  return (
    <Row>
      <Tabs
        items={[
          {
            key: '1',
            label: 'Contact',
            children: <Contact resume={resume} setResume={setResume} />,
          },
          {
            key: '2',
            label: 'Education',
            children: <Education resume={resume} setResume={setResume} />,
          },
          {
            key: '3',
            label: 'Work',
            children: <Work resume={resume} setResume={setResume} />,
          },
          {
            key: '4',
            label: 'Skills',
            children: <Skills resume={resume} setResume={setResume} />,
          },
        ]}
        defaultActiveKey="1"
      />
    </Row>
  )
}

export default Builder
