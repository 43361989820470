import { Button } from 'antd'
import React, { useRef, useEffect, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { useReactToPrint } from 'react-to-print'

const Download = ({ resumeRef, title }) => {
  const [isPrinting, setIsPrinting] = useState(false)

  const promiseResolveRef = useRef(null)

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])

  const downloadResume = useReactToPrint({
    content: () => resumeRef.current,
    onBeforePrint: () => {
      document.title = title
    },
    onAfterPrint: () => {
      document.title = title
      promiseResolveRef.current = null
      setIsPrinting(false)
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      })
    },
  })

  return (
    <Button
      type="primary button"
      icon={<DownloadOutlined />}
      onClick={downloadResume}
      className="cursor-pointer mt-2"
      style={{
        maxWidth: '135px',
      }}
    >
      {isPrinting ? 'Downloading' : 'Download'}
    </Button>
  )
}

export default Download
